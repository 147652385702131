/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

//
import React from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import MyCustomFooter from "examples/Footers/MyCustomFooter";

// Projects page sections
import Projects1 from "pages/Projects/sections/Projects_1";
import Projects2 from "pages/Projects/sections/Projects_2";
import Projects3 from "pages/Projects/sections/Projects_3";
import Projects4 from "pages/Projects/sections/Projects_4";
import Projects5 from "pages/Projects/sections/Projects_5";
import Projects6 from "pages/Projects/sections/Projects_6";
import Projects7 from "pages/Projects/sections/Projects_7";

// Routes
import routes from "routes";

function Projects() {
  return (
    <>
      <DefaultNavbar routes={routes} sticky />
      <MKBox bgColor="white">
        <MKBox
          minHeight="50vh"
          width="100%"
          sx={{
            backgroundImage: ({ functions: { linearGradient }, palette: { gradients } }) =>
              linearGradient(gradients.secondary.main, gradients.secondary.state),
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        >
          <Container>
            <Grid
              container
              item
              xs={12}
              lg={8}
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              sx={{ mx: "auto", textAlign: "center" }}
            >
              <MKTypography
                variant="h2"
                color="white"
                mb={2}
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                })}
              >
                Products
              </MKTypography>
            </Grid>
          </Container>
        </MKBox>
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          <Projects1 />
          <Projects2 />
          <Projects3 />
          <Projects4 />
          <Projects5 />
          <Projects6 />
          <Projects7 />
        </Card>
      </MKBox>
      <MKBox pt={6} px={1} mt={6}>
        <MyCustomFooter links={routes} />
      </MKBox>
    </>
  );
}

export default Projects;
