/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import Container from "@mui/material/Container";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";

// Images
import iso9001 from "assets/images/ISO9001.jpg";
import iso14001 from "assets/images/ISO14001.jpg";

function Projects() {
  return (
    <MKBox component="section" pt={6}>
      <Container>
        <Grid
          container
          item
          justifyContent="center"
          xs={12}
          lg={6}
          sx={{ mx: "auto", pb: 3, textAlign: "center" }}
        >
          <MKTypography variant="h2" my={1}>
            CERTIFICATE
          </MKTypography>
          <MKTypography variant="body1" color="text">
            엘에스에스이엔지의 앞선 첨단기술 특허 및 인증 현황을 확인할 수 있습니다. 객관적인 평가를
            통해 인증받음으로써 구매자와 조직에게 신뢰감을 제공하고 있습니다.
          </MKTypography>
        </Grid>
        <Grid
          container
          item
          xs={12}
          py={9}
          sx={{ px: { xs: 4, md: 8, xl: 12 } }}
          justifyContent="center"
        >
          <Grid container spacing={4}>
            <Grid item xs={12} md={6} xl={3}>
              <DefaultProjectCard image={iso9001} label="ISO9001" title="iso9001" description="" />
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <DefaultProjectCard
                image={iso14001}
                label="ISO14001"
                title="iso14001"
                description=""
              />
            </Grid>
            <Grid item xs={12} md={6} xl={3}></Grid>
            <Grid item xs={12} md={6} xl={3}></Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Projects;
