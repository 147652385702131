/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState } from "react";

import "bootstrap/dist/css/bootstrap.min.css";

import { Carousel, CarouselItem, CarouselControl, CarouselIndicators } from "reactstrap";

// Pricing page components
import SliderHeader from "pages/Index/components/Header";

// Images
import bg1 from "assets/images/bg11.jpg";
import bg2 from "assets/images/bg12.jpg";
import bg3 from "assets/images/bg14.jpg";

const items = [
  {
    content: (
      <SliderHeader
        image={bg1}
        label="AUTOMOBILE"
        title="자동차의 심장을 뛰게 만드는 힘"
        description="엘에스에스이엔지의 설비는 최첨단 기술을 기반으로 설계 및 제작되었으며, 높은 생산성, 품질, 안정성을 제공합니다. 또한, 고객의 요구사항을 반영하여 다양한 종류의 부품을 생산할 수 있습니다."
      />
    ),
    src: "0",
    altText: "",
    caption: "",
  },
  {
    content: (
      <SliderHeader
        image={bg2}
        label="SEMICONDUCTOR"
        title="세계를 움직이는 힘, 반도체 미래 견인"
        description="반도체는 컴퓨터, 스마트폰, 자동차, 가전제품 등 다양한 전자 제품에 사용되는 핵심 부품입니다. 반도체의 성능과 품질은 제품의 성능과 경쟁력을 결정짓는 중용한 요소입니다. "
      />
    ),
    src: "1",
    altText: "",
    caption: "",
  },
  {
    content: (
      <SliderHeader
        image={bg3}
        label="LOGISTICS"
        title="빠르고 정확하게 고객의 성공을 위한 동반자"
        description="물류 시스템은 생산부터 배송되는 모든 과정을 관리하는 시스템입니다. 이 시스템은 최첨단 기술을 기반으로 설계 및 운영되며, 높은 효율성, 정확성, 안정성을 제공합니다. "
      />
    ),
    src: "2",
    altText: "",
    caption: "",
  },
];

function Header() {
  // install SwiperJS modules
  // SwiperCore.use([Autoplay, Navigation]);

  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
      >
        {item.content}
      </CarouselItem>
    );
  });

  return (
    <Carousel activeIndex={activeIndex} next={next} previous={previous}>
      <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />
      {slides}
      <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
      <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
    </Carousel>
  );
}

export default Header;
