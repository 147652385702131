/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React components
import SimpleBlogCard from "examples/Cards/BlogCards/SimpleBlogCard";

import lssProgram1 from "assets/images/lss_program_1.png";
import lssProgram2 from "assets/images/lss_program_2.png";
import lssProgram3 from "assets/images/lss_program_3.png";
import lssProgram4 from "assets/images/lss_program_4.png";
import lssProgram5 from "assets/images/lss_program_5.jpg";
import lssProgram6 from "assets/images/lss_program_6.png";
import lssProgram7 from "assets/images/lss_program_7.png";
import lssProgram8 from "assets/images/lss_program_8.png";

function Projects6() {
  const actionProps = {
    type: "internal",
    route: "/pages/landing-pages/coworking",
    color: "dark",
    label: "find more",
  };

  return (
    <MKBox component="section" py={6}>
      <Container>
        <Grid
          container
          item
          flexDirection="column"
          alignItems="center"
          xs={12}
          lg={6}
          sx={{ textAlign: "center", mx: "auto" }}
        >
          <MKTypography variant="h2" mb={1}>
            PROGRAM & HARDWARE{" "}
          </MKTypography>
          <MKTypography variant="body1" color="text">
            소프트웨어 및 하드웨어 개발 역량을 갖추어 고객의 특정 요구 사항에 맞춘 완벽한 솔루션을
            제공합니다. 소프트웨어 및 하드웨어 개발 프로세스를 효율적으로 통합하여 시간과 비용을
            절감하며 완벽한 호환성을 보장합니다.
          </MKTypography>
        </Grid>
        <Grid container spacing={3} sx={{ mt: 3 }}>
          <Grid item xs={12} md={6} lg={3}>
            <MKBox mt={3}>
              <SimpleBlogCard
                image={lssProgram1}
                title="모니터링프로그램"
                description="자세한 내용은 문의 바랍니다."
                action={actionProps}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MKBox mt={3}>
              <SimpleBlogCard
                image={lssProgram2}
                title="성능검사프로그램"
                description="자세한 내용은 문의 바랍니다."
                action={actionProps}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MKBox mt={3}>
              <SimpleBlogCard
                image={lssProgram3}
                title="성능검사프로그램"
                description="자세한 내용은 문의 바랍니다."
                action={actionProps}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MKBox mt={3}>
              <SimpleBlogCard
                image={lssProgram4}
                title="WEB & APP 개발"
                description="자세한 내용은 문의 바랍니다."
                action={actionProps}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MKBox mt={3}>
              <SimpleBlogCard
                image={lssProgram5}
                title="회로설계"
                description="자세한 내용은 문의 바랍니다."
                action={actionProps}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MKBox mt={3}>
              <SimpleBlogCard
                image={lssProgram6}
                title="PCB ARTWORK"
                description="자세한 내용은 문의 바랍니다."
                action={actionProps}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MKBox mt={3}>
              <SimpleBlogCard
                image={lssProgram7}
                title="PCB ARTWORK"
                description="자세한 내용은 문의 바랍니다."
                action={actionProps}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MKBox mt={3}>
              <SimpleBlogCard
                image={lssProgram8}
                title="PCB 제작"
                description="자세한 내용은 문의 바랍니다."
                action={actionProps}
              />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Projects6;
