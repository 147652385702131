/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function SocialAnalytics() {
  return (
    <MKBox component="section" pt={6}>
      <Container>
        <Grid
          container
          item
          justifyContent="center"
          xs={12}
          lg={6}
          sx={{ mx: "auto", pb: 3, textAlign: "center" }}
        >
          <MKTypography variant="h2" my={1}>
            Company History
          </MKTypography>
          <MKTypography variant="body1" color="text">
            엘에스에스이엔지의 기술, 제품에 관심 있는 파트너사, 고객사의 협업과 제휴, 구매 문의에
            언제든 열려 있습니다.
          </MKTypography>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default SocialAnalytics;
