/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import SimpleInfoCard from "examples/Cards/InfoCards/SimpleInfoCard";

function Information() {
  return (
    <MKBox component="section" py={18}>
      <Container>
        <Grid
          container
          item
          justifyContent="center"
          xs={12}
          lg={8}
          sx={{ mx: "auto", pb: 3, textAlign: "center" }}
        >
          <MKTypography variant="h2" my={1}>
            엘에스에스이엔지의 비즈니스 모델을 소개합니다.
          </MKTypography>
          <MKTypography variant="body1" color="text" pt={6}>
            고객의 요구사항을 정확하게 파악하고, 최적의 설비를 설계 및 제작하여 고객의 생산성 향상
            및 품질 개선에 기여하는 솔루션 제공 회사입니다. 생산부터 물류, 성능 검사까지 모든 과정을
            아우르는 Total Solution을 제공하여 고객의 비즈니스 성공을 지원하겠습니다.
          </MKTypography>
        </Grid>
        <Grid container item xs={12} pt={6} justifyContent="center">
          <Grid
            item
            xs={12}
            md={4}
            sx={{ ml: { xs: 0, md: "auto" }, mr: { xs: 0, md: 6 }, mb: { xs: 4, md: 0 } }}
          >
            <Stack spacing={{ xs: 4, md: 8 }}>
              <SimpleInfoCard
                icon="factory"
                title="생산 관련 설비"
                description="생산 관련 설비는 제품을 생산하기 위해 필요한 모든 설비를 말합니다. 생산 관련 설비는 제품의 생산성 향상, 품질 개선, 원가 절감 등에 기여합니다."
              />
              <SimpleInfoCard
                icon="precision_manufacturing"
                title="전용설비"
                description="전용설비는 특정 제품의 생산에만 사용되는 설비를 말합니다. 전용설비는 제품의 특성에 맞게 설계 및 제작되기 때문에 높은 생산성 및 품질을 제공할 수 있습니다."
              />
              <SimpleInfoCard
                icon="biotech"
                title="성능 검사기"
                description="성능 검사기는 제품의 성능을 검사하는데 사용되는 장비입니다. 성능 검사기는 제품의 품질을 보장하고, 고객의 안전을 보호하는데 중요한 역할을 합니다."
              />
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            sx={{ mr: { xs: 0, md: "auto" }, ml: { xs: 0, md: 6 }, mb: { xs: 4, md: 0 } }}
          >
            <Stack spacing={{ xs: 4, md: 8 }}>
              <SimpleInfoCard
                icon="engineering"
                title="자동화 라인 시스템"
                description="자동화 장비는 제품의 생산 공정을 자동화하는데 사용하는 설비입니다. 자동화 라인 시스템은 다양한 산업 분야에서 활둉되고 있습니다. 대표적인 분야로는 자동차, 전자 등이 있습니다."
              />
              <SimpleInfoCard
                icon="inventory_2"
                title="물류 시스템"
                description="물류 시스템은 고객의 효율성 향상, 비용 절감, 고객 만족도 향상에기여하는 핵심적인 역할을 합니다. 최적의 물류 시스템을 설계 및 운영하여 성공을 지원해야 합니다."
              />
              <SimpleInfoCard
                icon="memory"
                title="H/W, S/W"
                description="전자회로 설계, PCB 설계, 임베디드 소프트웨어, 응용프로그램, 산업용 제어 프로그램, 안드로이드 앱, 웹 및 서버 개발 등 고객사에게 최상의 솔루션을 제공하고 있습니다."
              />
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
