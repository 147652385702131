/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const timelineData = [
  {
    color: "success",
    icon: "factory",
    title: "엘에스에스엔지니어링 설립",
    dateTime: "2013년",
    description: "범용 밀링 1기 도입",
    badges: ["design"],
  },
  {
    color: "error",
    icon: "local_shipping",
    title: "회사 사옥 이전",
    dateTime: "2016년",
    description: "성형 연마기 1기 도입",
    badges: ["order", "#1832412"],
  },
  {
    color: "info",
    icon: "shopping_cart",
    title: "MCT 6.5호기 3기 도입",
    dateTime: "2019년",
    description: "범용 선반 1기 도입",
    badges: ["server", "payments"],
  },
  {
    color: "warning",
    icon: "autorenew",
    title: "(주)엘에스에스이엔지 법인 전환",
    dateTime: "2022년",
    description: "MCT 5호기 1기 도입",
    badges: ["card", "#4395133", "priority"],
  },
];

export default timelineData;
