/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function SocialAnalytics() {
  return (
    <MKBox component="section" pt={18}>
      <Container>
        <Grid
          container
          item
          justifyContent="center"
          xs={12}
          lg={8}
          sx={{ mx: "auto", pb: 3, textAlign: "center" }}
        >
          <MKTypography variant="h2" my={1}>
            엘에스에스이엔지는 Total Solution 회사입니다.
          </MKTypography>
          <MKTypography variant="body1" color="text" pt={6}>
            엘에스에스이엔지는 공장 자동화 설비를 설계부터 완제품 생산까지 전 공정의 설비를
            전문적으로 제조하고 있으며 고객의 요구에 맞는 자동화 시스템의 Total Solution을 제공하기
            위해 핵심기술을 끊임없이 연구개발하고 있습니다.
            <br />
            2013년부터 끊임없는 발전을 위해 설비 증설을 통한 확장과 전문 인력 채용을 통한 전문성
            상승을 기반으로 서비스의 퀄리티를 상승시키고 발전을 멈추지 않고 있습니다
          </MKTypography>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default SocialAnalytics;
