/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React components
import SimpleBlogCard from "examples/Cards/BlogCards/SimpleBlogCard";

import lssProjects1 from "assets/images/lss_projects_1.jpg";
import lssProjects2 from "assets/images/lss_projects_2.jpg";
import lssProjects3 from "assets/images/lss_projects_3.jpg";
import lssProjects4 from "assets/images/lss_projects_4.jpg";
import lssProjects5 from "assets/images/lss_projects_5.jpg";
import lssProjects6 from "assets/images/lss_projects_6.jpg";
import lssProjects7 from "assets/images/lss_projects_7.jpg";

function Projects1() {
  const actionProps = {
    type: "internal",
    route: "/pages/landing-pages/coworking",
    color: "dark",
    label: "find more",
  };

  return (
    <MKBox component="section" py={6}>
      <Container>
        <Grid
          container
          item
          flexDirection="column"
          alignItems="center"
          xs={12}
          lg={6}
          sx={{ textAlign: "center", mx: "auto" }}
        >
          <MKTypography variant="h2" mb={1}>
            자동차 부품 생산 설비{" "}
          </MKTypography>
          <MKTypography variant="body1" color="text">
            수준 높은 엔지니어링과 제작, 설치 기술을 바탕으로 글로벌 자동차 업체에 첨단 자동차 생산
            설비를 제공하고 있습니다.
          </MKTypography>
        </Grid>
        <Grid container spacing={3} sx={{ mt: 3 }}>
          <Grid item xs={12} md={6} lg={3}>
            <MKBox mt={3}>
              <SimpleBlogCard
                image={lssProjects1}
                title="자동차 부품 생산 설비"
                description="자세한 내용은 문의 바랍니다."
                action={actionProps}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MKBox mt={3}>
              <SimpleBlogCard
                image={lssProjects2}
                title="자동차 부품 생산 설비"
                description="자세한 내용은 문의 바랍니다."
                action={actionProps}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MKBox mt={3}>
              <SimpleBlogCard
                image={lssProjects3}
                title="자동차 부품 생산 설비"
                description="자세한 내용은 문의 바랍니다."
                action={actionProps}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MKBox mt={3}>
              <SimpleBlogCard
                image={lssProjects4}
                title="자동차 부품 생산 설비"
                description="자세한 내용은 문의 바랍니다."
                action={actionProps}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MKBox mt={3}>
              <SimpleBlogCard
                image={lssProjects5}
                title="자동차 부품 생산 설비"
                description="자세한 내용은 문의 바랍니다."
                action={actionProps}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MKBox mt={3}>
              <SimpleBlogCard
                image={lssProjects6}
                title="자동차 부품 생산 설비"
                description="자세한 내용은 문의 바랍니다."
                action={actionProps}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MKBox mt={3}>
              <SimpleBlogCard
                image={lssProjects7}
                title="자동차 부품 생산 설비"
                description="자세한 내용은 문의 바랍니다."
                action={actionProps}
              />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Projects1;
