/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React components
import SimpleBlogCard from "examples/Cards/BlogCards/SimpleBlogCard";

import lssPlc1 from "assets/images/lss_plc_1.jpg";
import lssPlc2 from "assets/images/lss_plc_2.png";
import lssPlc3 from "assets/images/lss_plc_3.png";
import lssPlc4 from "assets/images/lss_plc_4.jpg";

function Projects7() {
  const actionProps = {
    type: "internal",
    route: "/pages/landing-pages/coworking",
    color: "dark",
    label: "find more",
  };

  return (
    <MKBox component="section" py={6}>
      <Container>
        <Grid
          container
          item
          flexDirection="column"
          alignItems="center"
          xs={12}
          lg={6}
          sx={{ textAlign: "center", mx: "auto" }}
        >
          <MKTypography variant="h2" mb={1}>
            PLC{" "}
          </MKTypography>
          <MKTypography variant="body1" color="text">
            다양한 산업 분야에서 PLC 시스템 구축 경험을 보유하고 있습니다. 숙련된 엔지니어 팀으로
            구성하여 최적의 PLC 시스템을 설계 및 구축하여 제공하고 있습니다.
          </MKTypography>
        </Grid>
        <Grid container spacing={3} sx={{ mt: 3 }}>
          <Grid item xs={12} md={6} lg={3}>
            <MKBox mt={3}>
              <SimpleBlogCard
                image={lssPlc1}
                title="PLC 시스템 구축"
                description="자세한 내용은 문의 바랍니다."
                action={actionProps}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MKBox mt={3}>
              <SimpleBlogCard
                image={lssPlc2}
                title="LS산전 PLC"
                description="자세한 내용은 문의 바랍니다."
                action={actionProps}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MKBox mt={3}>
              <SimpleBlogCard
                image={lssPlc3}
                title="미쓰비스 PLC"
                description="자세한 내용은 문의 바랍니다."
                action={actionProps}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MKBox mt={3}>
              <SimpleBlogCard
                image={lssPlc4}
                title="PLC 시스템 구축"
                description="자세한 내용은 문의 바랍니다."
                action={actionProps}
              />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Projects7;
