/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import MyCustomFooter from "examples/Footers/MyCustomFooter";

// index page sections
import Header from "pages/Index/sections/Header";
import SocialAnalytics from "pages/Index/sections/SocialAnalytics";
import Information from "pages/Index/sections/Information";

// Routes
import routes from "routes";

function Index() {
  return (
    <>
      <DefaultNavbar routes={routes} sticky />
      <Header />
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <SocialAnalytics />
        <Information />
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <MyCustomFooter links={routes} />
      </MKBox>
    </>
  );
}

export default Index;
