/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import Container from "@mui/material/Container";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";

// Images
import equipment1 from "assets/images/lss_equipment_1.jpg";
import equipment2 from "assets/images/lss_equipment_2.jpg";
import equipment3 from "assets/images/lss_equipment_3.jpg";
import equipment4 from "assets/images/lss_equipment_4.jpg";

function Projects() {
  return (
    <MKBox component="section" py={6}>
      <Container>
        <Grid
          container
          item
          justifyContent="center"
          xs={12}
          lg={8}
          sx={{ mx: "auto", pb: 3, textAlign: "center" }}
        >
          <MKTypography variant="h2" my={1}>
            엘에스에스이엔지 보유 장비
          </MKTypography>
          <MKTypography variant="body1" color="text" pt={6}>
            MCT 6.5호기(3기), MCT 5호기(1기), 범용밀링(1기), 범용선반(1기)를 보유하면서 자체 가공을
            통해 긴급대응, 납기 단축을 실현하고 있습니다.
          </MKTypography>
        </Grid>
        <Grid container item xs={12} pt={6} sx={{ px: { sx: 0, lg: 12 } }} justifyContent="center">
          <Grid container spacing={4}>
            <Grid item xs={12} md={6} xl={3}>
              <DefaultProjectCard image={equipment1} label="MCT" title="MCT" description="" />
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <DefaultProjectCard
                image={equipment2}
                label="범용밀링"
                title="범용밀링"
                description=""
              />
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <DefaultProjectCard
                image={equipment3}
                label="범용선반"
                title="범용선반"
                description=""
              />
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <DefaultProjectCard
                image={equipment4}
                label="성형연마"
                title="성형연마"
                description=""
              />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Projects;
