/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function TimelineList({ title, dark, children }) {
  return (
    <Card>
      <MKBox
        bgColor={dark ? "dark" : "white"}
        variant="gradient"
        borderRadius="xl"
        sx={{ background: ({ palette: { background } }) => background.card }}
      >
        <MKBox pt={3} px={3}>
          <MKTypography variant="h6" fontWeight="medium" color={dark ? "white" : "dark"}>
            {title}
          </MKTypography>
        </MKBox>
        <MKBox p={2}>{children}</MKBox>
      </MKBox>
    </Card>
  );
}

// Setting default values for the props of TimelineList
TimelineList.defaultProps = {
  dark: false,
};

// Typechecking props for the TimelineList
TimelineList.propTypes = {
  title: PropTypes.string.isRequired,
  dark: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default TimelineList;
