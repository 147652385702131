/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// About Us page sections
import TimelineList from "examples/Timeline/TimelineList";
import TimelineItem from "examples/Timeline/TimelineItem";

// Data
import timelineData from "pages/AboutUs/Overview/data/timelineData";

function TimeLine() {
  const renderTimelineItems = timelineData.map(
    ({ color, icon, title, dateTime, description, badges, lastItem }) => (
      <TimelineItem
        key={title + color}
        color={color}
        icon={icon}
        title={title}
        dateTime={dateTime}
        description={description}
        badges={badges}
        lastItem={lastItem}
      />
    )
  );

  return (
    <MKBox component="section" position="relative" pb={6}>
      <Container sx={{ maxWidth: "100% !important" }}>
        <Grid container item xs={10} sx={{ mx: "auto" }}>
          <MKBox variant="gradient" bgColor="white" borderRadius="lg" width="100%" py={6}>
            <Grid container>
              <Grid
                xs={1}
                sm={1}
                lg={2}
                xl={2}
                sx={{ position: "relative", px: { xs: 0, md: 2 } }}
              ></Grid>
              <Grid
                item
                xs={12}
                sm={12}
                lg={8}
                xl={8}
                sx={{
                  position: "relative",
                  px: { xs: 0, md: 2 },
                  mt: { xs: 3, md: 0 },
                  my: { xs: 0, md: "auto" },
                  color: ({ palette: { white } }) => white.main,
                }}
              >
                <TimelineList title="Timeline">{renderTimelineItems}</TimelineList>
              </Grid>
              <Grid
                xs={12}
                sm={12}
                lg={2}
                xl={2}
                sx={{ position: "relative", px: { xs: 0, md: 2 } }}
              ></Grid>
            </Grid>
          </MKBox>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default TimeLine;
