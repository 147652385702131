/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React components
import SimpleBlogCard from "examples/Cards/BlogCards/SimpleBlogCard";

import lssProjects11 from "assets/images/lss_projects_11.jpg";

function Projects4() {
  const actionProps = {
    type: "internal",
    route: "/pages/landing-pages/coworking",
    color: "dark",
    label: "find more",
  };

  return (
    <MKBox component="section" py={6}>
      <Container>
        <Grid
          container
          item
          flexDirection="column"
          alignItems="center"
          xs={12}
          lg={6}
          sx={{ textAlign: "center", mx: "auto" }}
        >
          <MKTypography variant="h2" mb={1}>
            휴대폰 카메라 조립 설비{" "}
          </MKTypography>
          <MKTypography variant="body1" color="text">
            휴대폰 카메라 조립 설비는 고도의 정밀성과 신뢰성을 요구합니다. 또한, 휴대폰 카메라의
            성능 향상에 따라 설비의 기술 수준도 지속적으로 발전하고 있습니다.
          </MKTypography>
        </Grid>
        <Grid container spacing={3} sx={{ mt: 3 }}>
          <Grid item xs={12} md={6} lg={3}>
            <MKBox mt={3}>
              <SimpleBlogCard
                image={lssProjects11}
                title="휴대폰 카메라 조립 설비"
                description="자세한 내용은 문의 바랍니다."
                action={actionProps}
              />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Projects4;
