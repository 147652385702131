/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

//
import React from "react";
import { Wrapper, Status } from "@googlemaps/react-wrapper";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

const render = (status) => {
  switch (status) {
    case Status.LOADING:
      return <div />;
    case Status.FAILURE:
      return <div />;
    case Status.SUCCESS:
      return <MyMapComponent />;
  }
};

function MyMapComponent() {
  const ref = React.useRef(null);

  React.useEffect(() => {
    if (ref.current) {
      const map = new window.google.maps.Map(ref.current, {
        center: { lat: 37.1497763, lng: 126.9673846 },
        zoom: 16,
      });
      new window.google.maps.Marker({
        position: { lat: 37.1497763, lng: 126.9673846 },
        map,
      });
    }
  }, [ref]);
  return (
    <>
      <div ref={ref} id="map" style={{ width: "100%", height: "400px" }}></div>
    </>
  );
}

function SocialAnalytics() {
  return (
    <MKBox component="section" py={6}>
      <Container>
        <Grid
          container
          item
          justifyContent="center"
          xs={12}
          lg={6}
          sx={{ mx: "auto", pb: 3, textAlign: "center" }}
        >
          <MKTypography variant="h2" my={1}>
            We are Waiting for You
          </MKTypography>
          <MKTypography variant="body1" color="text">
            엘에스에스이엔지의 기술, 제품에 관심 있는 파트너사, 고객사의 협업과 제휴, 구매 문의에
            언제든 열려 있습니다.
          </MKTypography>
        </Grid>
        <MKBox sx={{ pt: 10 }}>
          <Wrapper apiKey={"AIzaSyCG8EpFUnle-GgWFAomcGdspXUVcO-HEqs"} render={render} />
        </MKBox>
        <Grid container spacing={3} sx={{ mt: 3 }}>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultInfoCard
              icon="location_on"
              title="찾아오시는 길"
              description="경기도 화성시 정남면 문학로 68번길 26-19"
              direction="center"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultInfoCard
              icon="local_phone"
              title="문의 전화"
              description="031-366-1103"
              direction="center"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4} sx={{ mx: "auto" }}>
            <DefaultInfoCard
              icon="mail_outline"
              title="전자우편"
              description="lsseng@lsseng.co.kr"
              direction="center"
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default SocialAnalytics;
